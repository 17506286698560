<template>
  <div>
    <large-layout>
      <div class="col-12 text-center d-flex flex-column gap-4 mt-2">
        <h2>404</h2>
        <h3>Page not found!</h3>
      </div>
    </large-layout>
  </div>
</template>

<script>
import LargeLayout from "../layout/main/LargeLayout.vue";
export default {
  components: { LargeLayout },
};
</script>

<style></style>
